h1 {
  margin: 0 0;
  font-size: 1.2em;
  color: #00ff98;
  text-transform: uppercase;

}

h2 {
  margin: 0 0;
  font-size: 1em;
  color: #00cbff;
  text-transform: uppercase;
}

p {
  margin: 0.5em 0;
}

h4 {
  color: #00cbff;
  text-transform: uppercase;
  margin: 0.2em 0;
}

.App-container {
  display: flex;
  flex-direction: row
}

.App-background {
  position: relative;
  height: fit-content;
  flex: 1 1
}

.App-box {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  max-width: 30%;
  height: 100%
}

.App-description {
  flex-direction: column;
  overflow: auto;
  padding: 0.3em 1em;
}

.App-audio {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  flex-direction: column;
}

@media (max-width: 945px) {
  .App-container {
    display: flex;
    flex-direction: column
  }

  .App-box {
    max-width: 100%
  }

  .App-description {
    flex: 1 2 auto;
  }
}